<template>
  <div class="table">
    <!-- 表格 -->
    <el-table
      :data="arr"
      style="width: 100%"
      :default-sort="{ prop: 'date', order: 'descending' }"
      border
      :cell-style="{ padding: '0' }"
      :row-style="{ height: '64px' }"
      :header-cell-style="{ background: '#FAFAF9', height: '40px' }"
      @cell-mouse-enter="cellMouseEnter"
      @cell-mouse-leave="cellMouseLeave"
      @selection-change="handleSelectionChange"
      :height="tableheight"
      :class="deleteform ? 'batchtable' : ''"
    >
      <el-table-column
        type="selection"
        width="55"
        :selectable="checkDis"
        v-if="selectionFlg"
      >
      </el-table-column>
      <template v-for="(tit, titindex) in tableAttr">
        <slot :item="titindex">
          <el-table-column v-if="tit.ee == 'A'" :width="tit.width">
            <template slot="header">
              <div class="tableHeadField" v-show="!deleteform">
                <el-popover
                  placement="top-start"
                  trigger="hover"
                  popper-class="my-tooltip table-head-tooltip"
                  :content="tit.aa"
                >
                  <span slot="reference">
                    {{ tit.aa }}
                  </span>
                </el-popover>
                <svg
                  class="icon"
                  v-show="tit.cc"
                  :style="{
                    height: '12px',
                    'vertical-align': '',
                    transform: tit.dd ? 'rotate(180deg)' : '',
                  }"
                  aria-hidden="true"
                >
                  <use href="#icon-icon_sort_top"></use>
                </svg>
                <el-popover
                  placement="bottom"
                  width="100"
                  trigger="hover"
                  :visible-arrow="false"
                  class="popover-span"
                  :ref="`${tit.aa}-popover`"
                  v-if="showLock"
                >
                  <el-checkbox>{{
                    $t("vue_label_commonobjects_detail_locking")
                  }}</el-checkbox>
                  <span slot="reference" @click="titimg(tit)">
                    <svg class="icon" style="width: 11px" aria-hidden="true">
                      <use href="#icon-xiajiantou"></use>
                    </svg>
                  </span>
                </el-popover>
              </div>
              <div class="batchremove" v-show="deleteform">
                {{ selectnumber }} forms selected
                <svg class="icon" aria-hidden="true">
                  <use href="#icon-delete"></use>
                </svg>
                <span @click="deleteformevent">Delete</span>
              </div>
            </template>
            <template slot-scope="scope">
              <div class="tablename">
                <div class="tablenamefirst">
                  <svg
                    class="icon"
                    style="width: 11px; height: 11px"
                    v-if="scope.row.img"
                    aria-hidden="true"
                  >
                    <use :href="scope.row.img"></use>
                  </svg>
                  <span
                    class="tablenamebloder"
                    @click="toparticulars(scope.row.name)"
                  >
                    {{ scope.row.name }}
                  </span>
                </div>
                <!-- 操作列表，最后一个操作列 -->
                <div v-show="scope.row.vshow" class="tablenamesecond">
                  <button
                    v-for="(but, butnum) in buttonlist"
                    :key="butnum"
                    class="actionselect"
                    @click="action(scope.row, butnum, but)"
                  >
                    {{ but }}
                    <i
                      class="el-icon-caret-bottom"
                      v-if="butnum === buttonlist.length - 1"
                    ></i>
                    <ul
                      :class="
                        scope.$index + 1 == pagesize ||
                        scope.$index + 1 == pagesize - 1
                          ? 'actiontop'
                          : 'actionoption'
                      "
                      v-show="scope.row.optionshow"
                    >
                      <li v-for="item in selectvalue.selectvalue1" :key="item">
                        {{ item }}
                        <svg
                          v-show="item == 'Create list of contacts'"
                          class="icon"
                          aria-hidden="true"
                        >
                          <use href="#icon-shareblue"></use>
                        </svg>
                      </li>
                      <div
                        class="borderli"
                        v-if="selectvalue.selectvalue2.length > 0"
                      ></div>
                      <li v-for="itmt in selectvalue.selectvalue2" :key="itmt">
                        {{ itmt }}
                      </li>
                    </ul>
                  </button>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column v-if="tit.ee == 'B'" :width="tit.width">
            <template slot="header">
              <div class="tableHeadField" v-show="!deleteform">
                <el-popover
                  placement="top-start"
                  trigger="hover"
                  popper-class="my-tooltip table-head-tooltip"
                  :content="tit.aa"
                >
                  <span slot="reference">
                    {{ tit.aa }}
                  </span>
                </el-popover>
                <svg
                  class="icon"
                  v-show="tit.cc"
                  :style="{
                    height: '12px',
                    'vertical-align': '',
                    transform: tit.dd ? 'rotate(180deg)' : '',
                  }"
                  aria-hidden="true"
                >
                  <use href="#icon-icon_sort_top"></use>
                </svg>
                <el-popover
                  placement="bottom"
                  width="100"
                  trigger="hover"
                  :visible-arrow="false"
                  class="popover-span"
                  :ref="`${tit.aa}-popover`"
                  v-if="showLock"
                >
                  <el-checkbox>{{
                    $t("vue_label_commonobjects_detail_locking")
                  }}</el-checkbox>
                  <span slot="reference" @click="titimg(tit)">
                    <svg class="icon" style="width: 11px" aria-hidden="true">
                      <use href="#icon-xiajiantou"></use>
                    </svg>
                  </span>
                </el-popover>
              </div>
              <div class="batchremove" v-show="deleteform"></div>
            </template>
            <template slot-scope="scope">
              <div>
                <span>{{ scope.row[tit.bb] }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column v-if="tit.ee == 'C'" :width="tit.width">
            <template slot="header">
              <div class="tableHeadField" v-show="!deleteform">
                <el-popover
                  placement="top-start"
                  trigger="hover"
                  popper-class="my-tooltip table-head-tooltip"
                  :content="tit.aa"
                >
                  <span slot="reference">
                    {{ tit.aa }}
                  </span>
                </el-popover>
                <svg
                  class="icon"
                  v-show="tit.cc"
                  :style="{
                    height: '12px',
                    'vertical-align': '',
                    transform: tit.dd ? 'rotate(180deg)' : '',
                  }"
                  aria-hidden="true"
                >
                  <use href="#icon-icon_sort_top"></use>
                </svg>
                <el-popover
                  placement="bottom"
                  width="100"
                  trigger="hover"
                  :visible-arrow="false"
                  class="popover-span"
                  :ref="`${tit.aa}-popover`"
                  v-if="showLock"
                >
                  <el-checkbox>{{
                    $t("vue_label_commonobjects_detail_locking")
                  }}</el-checkbox>
                  <span slot="reference" @click="titimg(tit)">
                    <svg class="icon" style="width: 11px" aria-hidden="true">
                      <use href="#icon-xiajiantou"></use>
                    </svg>
                  </span>
                </el-popover>
              </div>
              <div class="batchremove" v-show="deleteform"></div>
            </template>
            <template slot-scope="scope">
              <div>
                <el-checkbox v-model="scope.row[tit.bb]"></el-checkbox>
              </div>
            </template>
          </el-table-column>
          <el-table-column v-if="tit.ee == 'D'" :width="tit.width">
            <template slot="header">
              <div class="tableHeadField" v-show="!deleteform">
                <el-popover
                  placement="top-start"
                  trigger="hover"
                  popper-class="my-tooltip table-head-tooltip"
                  :content="tit.aa"
                >
                  <span slot="reference">
                    {{ tit.aa }}
                  </span>
                </el-popover>
                <svg
                  class="icon"
                  v-show="tit.cc"
                  :style="{
                    height: '12px',
                    'vertical-align': '',
                    transform: tit.dd ? 'rotate(180deg)' : '',
                  }"
                  aria-hidden="true"
                >
                  <use href="#icon-icon_sort_top"></use>
                </svg>
                <el-popover
                  placement="bottom"
                  width="100"
                  trigger="hover"
                  :visible-arrow="false"
                  class="popover-span"
                  :ref="`${tit.aa}-popover`"
                  v-if="showLock"
                >
                  <el-checkbox>{{
                    $t("vue_label_commonobjects_detail_locking")
                  }}</el-checkbox>
                  <span slot="reference" @click="titimg(tit)">
                    <svg class="icon" style="width: 11px" aria-hidden="true">
                      <use href="#icon-xiajiantou"></use>
                    </svg>
                  </span>
                </el-popover>
              </div>
              <div class="batchremove" v-show="deleteform"></div>
            </template>
            <template slot-scope="scope">
              <div class="account">
                <svg
                  class="icon"
                  style="width: 40px; height: 26px"
                  aria-hidden="true"
                >
                  <use href="#icon-touxiang"></use>
                </svg>
                <div class="account_box">
                  <div class="accountName">
                    {{ scope.row[tit.bb].accountName }}
                  </div>
                  <div class="optionTips">
                    <div
                      class="round_img"
                      :style="`background:${scope.row[tit.bb].accountColor}`"
                    ></div>
                    <span class="ml-10">{{
                      scope.row[tit.bb].accountTip
                    }}</span>
                  </div>
                  <a @click="relink(scope)">{{
                    scope.row[tit.bb].accountSrc
                  }}</a>
                </div>
              </div>
            </template>
          </el-table-column>
        </slot>
      </template>
      <template slot="empty">
        <div class="isnot-box">
          <svg class="icon" aria-hidden="true">
            <use href="#icon-empty-search"></use>
          </svg>
          <div class="not-title">No list cleanup needed.</div>
          <div class="not-text">You don`t have any unused lists right now.</div>
        </div>
      </template>
    </el-table>

    <!-- 分页 -->
    <div class="block" v-show="pageshow">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[6, 10, 15, 20]"
        layout=" prev, pager, next,sizes"
        :page-size="pagesize"
        :total="currentPagetotle"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    // 是否拥有选择框
    selectionFlg: {
      type: Boolean,
      default() {
        return true;
      },
    },
    checkDisable: {
      // 表头
      type: Boolean,
      default() {
        return false;
      },
    },
    tableAttr: {
      // 表头
      type: Array,
      default() {
        return [];
      },
    },
    tableData: {
      // 内容
      type: Array,
      default() {
        return [];
      },
    },
    tableheight: {
      // 表格高度
      type: String,
      default: "",
    },
    pageshow: {
      //分页显示隐藏
      type: Boolean,
      default: false,
    },
    selectvalue: {
      //actions下拉
      type: Object,
      default() { },
    },
    buttonlist: {
      // 表格行内button
      type: Array,
      default() {
        return [];
      },
    },
  },

  components: {},
  data() {
    return {
      // --------------------------
      arr: [],
      //表格
      showLock: true,
      currentPage: 1,
      pagesize: 10,
      currentPagetotle: 0,
      deleteform: false, //控制表头成一个格
      selectnumber: null, //表格选中个数
      multipleSelection: [], //多选选中的
      tableDataD:this.tableData
    };
  },

  methods: {
    relink(scope) {
      this.$emit("relink", scope);
    },
    toparticulars(item) {
      this.$router.push({
        name: "particulars",
        params: {
          id: item,
          title: "Active list",
          acsta: false,
        },
      });
    },
    checkDis() {
      if (this.checkDisable) {
        return false;
      } else {
        return true;
      }
    },
    //表格划入
    cellMouseEnter(row) {
      row.vshow = true;
    },
    //表格滑出
    cellMouseLeave(row) {
      row.vshow = false;
      for (let i = 0; i < this.tableDataD.length; i++) {
        this.tableDataD[i].optionshow = false;
      }
    },
    //多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.selectnumber = val.length;
      if (val.length > 1) {
        this.deleteform = true;
      } else {
        this.deleteform = false;
      }
    },
    //表头排序图片翻转
    titimg(tit) {
      tit.cc = true;
      tit.dd = !tit.dd;
      //dd为false  向上
      //dd为true   向下
    },
    //批量删除
    deleteformevent() {
      for (var i = 0; i < this.multipleSelection.length; i++) {
        for (var j = 0; j < this.tableDataD.length; j++) {
          if (this.multipleSelection[i].name == this.tableDataD[j].name) {
            this.tableDataD.splice(j, 1);
          }
        }
      }
      this.tableDataD = [...this.tableDataD];
      this.currentPagetotle = this.tableDataD.length;
      this.showpage();
      this.deleteform = false;
    },
    action(key, butnum, but) {
      if (butnum === this.buttonlist.length - 1) {
        key.optionshow = true;
      }
      if (but == "Details") {
        this.$emit("showDrawer");
      }
    },
    //分页
    handleSizeChange(val) {
      this.pagesize = val;
      this.showpage();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.showpage();
    },
    showpage() {
      this.arr = [];
      let num = this.currentPage * this.pagesize;
      if (num >= this.tableDataD.length) {
        num = this.tableDataD.length;
      }
      for (let i = (this.currentPage - 1) * this.pagesize; i < num; i++) {
        this.arr.push(this.tableDataD[i]);
      }
    },
  },
  created() {
    this.currentPagetotle = this.tableDataD.length;
    this.showpage();
    let ndate = new Date();
    this.datepickervalue = [ndate, ndate];
  },
};
</script>
<style lang="scss" scoped>
.icon {
  display: inline-block;
}
.account {
  display: flex;
  justify-content: space-around;
  img {
    margin-top: 10px;
    width: 80px;
    height: 50px;
  }
  .account_box {
    .optionTips {
      display: flex;
      align-items: center;
      height: 30px;
      .round_img {
        border: 1px solid #dddbda;
        height: 10px;
        width: 10px;
        border-radius: 50%;
      }
      span {
        font-family: PingFangSC-Regular;
        font-size: 10px;
        color: #6b6969;
      }
      a {
        font-family: PingFangSC-Regular;
        font-size: 10px;
        color: #0771ce;
      }
    }
  }
}

.isnot-box {
  .icon {
    width: 200px;
    height: 110px;
  }
  .not-title {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #080707;
  }
  .not-text {
    font-family: MicrosoftYaHei;
    font-size: 12px;
    color: #888888;
  }
}
button {
  outline: none;
}
::v-deep .el-table th.is-leaf {
  color: #333;
  border-bottom: 1px solid #dedcda;
}

::v-deep .el-table--border th {
  border-right: 1px solid #dedcda;
}
::v-deep .batchtable {
  th {
    border-right: none;
  }
}
::v-deep .el-table__header tr th {
  padding: 8px;
  padding-left: 0;
  padding-right: 0;
}
::v-deep .el-table td {
  position: relative;
  padding: 9px 0;
  color: #080707;
  border-bottom: 1px solid #dedcda;
  word-break: break-all;
}

::v-deep .el-table--border td {
  border-right: none;
}

.line-two-btn {
  .btn {
    padding: 0;
    height: 29px;
    padding: 0 4px;
    border: 1px solid #dedcda;
  }

  img {
    width: 18px;
    height: 18px;
  }

  .group-btn {
    // margin-top: -20px;
    padding: 0 4px;
    width: 30px;
    background-size: auto auto;
    background-repeat: no-repeat;
    background-position: 4px;
  }
}
.el-button--primary.is-plain {
  color: #409eff;
  background: #fff;
  border-color: #ccc;
}
.el-button--primary.is-plain:hover {
  color: #ffffff;
  background: #006dcc;
}
.ivu-table-wrapper {
  overflow: visible;
}
.marketform {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .Form {
    width: 99%;
    height: 98%;
    margin-left: 10px;
    background: #fff;
  }
}
::v-deep .el-table {
  overflow: auto;
  overflow-x: hidden;
  width: 100%;
}
::v-deep .el-table__body-wrapper {
  overflow: auto;
  width: 100%;
  overflow-x: hidden;
}
.table {
  width: 100%;
}
.tablename {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  .tablenamefirst {
    color: #006dcc;
    font-size: 14px;
    cursor: pointer;
    .secol {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #006dcc;
      display: inline-block;
    }
    .tablenamebloder {
      font-weight: bolder;
      font-family: MicrosoftYaHei-Bold;
    }
    .tablesecol {
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #888888;
    }
  }
  .tablenamesecond {
    button {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #080707;
      letter-spacing: 0;
      background: #ffffff;
      border: 1px solid #dedcda;
      border-radius: 3px;
      margin-right: 10px;
      height: 30px;
      cursor: pointer;
    }
    .actionselect {
      background: #ffffff;
      border: 1px solid #dedcda;
      border-radius: 3px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #080707;
      letter-spacing: 0;
      height: 30px;
      .borderli {
        width: 100%;
        height: 1px;
        background: #dedcda;
        margin-top: 3px;
        margin-bottom: 3px;
      }
      .actionoption {
        position: absolute;
        right: -90px;
        top: 50px;
        background: #fff;
        z-index: 999;
        width: 190px;
        background: #ffffff;
        border: 1px solid #dddbda;
        box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.46);
        li {
          line-height: 32px;
          text-align: left;
          padding-left: 8px;
        }
        li:hover {
          background: #ececec;
          color: #006dcc;
        }
      }
      .actiontop {
        position: absolute;
        right: -50px;
        bottom: 50px;
        background: #fff;
        z-index: 9999;
        width: 190px;
        background: #ffffff;
        border: 1px solid #dddbda;
        box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.46);
        li {
          line-height: 32px;
          text-align: left;
          padding-left: 8px;
        }
        li:hover {
          background: #ececec;
          color: #006dcc;
        }
      }
    }
  }
}
.block {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  background: #ffff;
}
.texttf {
  width: 100%;
  height: 30px;
  font-weight: bolder;
  font-size: 14px;
  span {
    display: inline-block;
    width: 16px;
    height: 16px;
    text-align: center;
    line-height: 16px;
    background: #ccc;
    margin-left: 10px;
  }
  i {
    padding-right: 10px;
    color: #006dcc;
  }
}
::v-deep .dialog {
  .dialogdiv {
    width: 100%;
    .el-select {
      width: 100%;
    }
    input {
      width: 100%;
      border: 1px solid #dedcda;
      border-radius: 3px;
      height: 40px;
    }
    p {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #080707;
      letter-spacing: 0;
    }
    div {
      margin-bottom: 20px;
      p {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #666666;
        letter-spacing: 0;
      }
      b {
        color: #006dcc;
      }
    }
  }
}

.appearstyle {
  cursor: pointer;
}
.batchremove {
  padding-left: 5px;
  img {
    margin-left: 20px;
    margin-right: 8px;
  }
  span {
    color: #0277b5;
    cursor: pointer;
  }
}
.tableHeadField {
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 20px;
  text-overflow: ellipsis;

  .popover-span {
    position: absolute;
    right: 0;
  }
}
/* 筛选器位置 */
.screen {
  // height: 88%;
  height: calc(100% - 116px);
  position: absolute;
  right: 11px;
  top: 105px;
  z-index: 99;
}
</style>
